
.TwoFactorAuth-header {

}

.validation-code-form {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-code-input {
  display: block;
  width: 80%;
  background-color: transparent;
  color: #fffcfc;
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.25em;
  text-align: center;
  border: 0;
  border-bottom: #bdbdbd 1px solid;
  border-radius: 0px;
  padding-bottom: 7px;
  margin-top: 20px;
}

.validation-code-submit-button {
  display: block;
  width: 50%;
  margin-top: 20px;
  /* background: #6fcf97; */
  background: #0066cc;
  border-radius: 27px;
  border: 0;
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #f2f2f2;
  height: 55px;
  cursor: pointer;
}

.validation-code-error {
  /* color: #c4352b; */
  color: #fff;
}

.bottom-space {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-desc {
  width: 90%;
}

/* .bottom {
  margin-bottom: 40vh;
} */
