.btn {
  background: #6fcf97;
  border-radius: 27px;
  margin-top: 20px;
  height: 55px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn.secondary {
  background: #282c34;
  border: 2px solid #000000;
}

.btn p {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #f2f2f2;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btn-disabled {
  background: #333333;
  border-radius: 27px;
  margin-top: 20px;
  height: 55px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-disabled p {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #4f4f4f;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
