
.Complete-header {
  justify-content: space-between;
  background: #fec100;
  background-image: url(../../assets/complete-page-bg-img-min.png);
  background-image: url(../../assets/complete-page-bg-img-min.png), linear-gradient(180deg, #fdbc00 0%, #ffc500 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.easy-text {
  font-family: Proxima-Bold;
  font-style: normal;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 5%;
  color: #333333;
}
