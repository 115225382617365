.LandingPage {
  text-align: center;
}

.LandingPage-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .LandingPage-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.LandingPage-header {

}

.img {
  /* height: 370;
  width: 370; */
  width: 91px;
  margin-bottom: calc(30vh - 100px);
}

.LandingPage-link {
  color: #61dafb;
}

.mini-sect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
