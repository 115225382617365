.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.img {
  height: 370;
  width: 370;
}

.App-link {
  color: #61dafb;
}


.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-main {
  background: linear-gradient(180deg, #1f1f1f 0%, #333333 100%);
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top {
  margin-top: 25px;
  display: flex;
  width: 90vw;
  flex-direction: column;
}

.bottom {
  margin-bottom: 50px;
}

.heading-text {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.mini-desc {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fffefe;
  width: 100%;
}
