.FirstStep-header {
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  background: linear-gradient(180deg, #1f1f1f 20.83%, #333333 100%); */
}

.back {
  cursor: pointer;
}

.phone-nr {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.give-width {
  width: 95%;
}


/* .bottom {
  margin-bottom: 40vh;
} */
