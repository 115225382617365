@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@font-face {
  font-family: "Proxima";
  src: local("Proxima"),
    url(/static/media/ProximaNova-Regular.410504d4.otf) format("opentype");
}
@font-face {
  font-family: "Proxima-Bold";
  src: local("Proxima-Bold"),
    url("/static/media/Proxima Nova Alt Bold.9d62cb9f.otf") format("opentype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.img {
  height: 370;
  width: 370;
}

.App-link {
  color: #61dafb;
}


.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-main {
  background: linear-gradient(180deg, #1f1f1f 0%, #333333 100%);
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top {
  margin-top: 25px;
  display: flex;
  width: 90vw;
  flex-direction: column;
}

.bottom {
  margin-bottom: 50px;
}

.heading-text {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.mini-desc {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fffefe;
  width: 100%;
}

.btn {
  background: #6fcf97;
  border-radius: 27px;
  margin-top: 20px;
  height: 55px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn.secondary {
  background: #282c34;
  border: 2px solid #000000;
}

.btn p {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #f2f2f2;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btn-disabled {
  background: #333333;
  border-radius: 27px;
  margin-top: 20px;
  height: 55px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-disabled p {
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #4f4f4f;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.FirstStep-header {
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  background: linear-gradient(180deg, #1f1f1f 20.83%, #333333 100%); */
}

.back {
  cursor: pointer;
}

.phone-nr {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.give-width {
  width: 95%;
}


/* .bottom {
  margin-bottom: 40vh;
} */

.PhoneInput {
  flex: 1 1;
}
.PhoneInputCountryIcon {
  border-radius: 360px !important;
  overflow: hidden !important;
  border-width: 0px !important;
  width: 100%;
  height: 100%;
}
.PhoneInputCountrySelectArrow {
  display: none !important;
}
.PhoneInputInput {
  border: 0px !important;
  background-color: transparent;
  color: #fffcfc;
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.25em;
  text-align: left;

  border-radius: 0px;
  border-bottom: #bdbdbd 1px solid !important;
  padding-bottom: 7px;
  padding-left: 50px;
  box-sizing: border-box;
  width: calc(100% - 50px);
}
.wrapp {
  position: relative;
  display: flex;
  flex-direction: row;
  /* border-bottom: #bdbdbd 1px solid; */
  /* justify-content: space-between; */
  /* padding-bottom: 7px; */
}
.verify-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  position: absolute;
  right: 0;
}
.verify-badge img {
  width: 15px;
  height: 15px;
}
.verify-badge p {
  margin: 0px;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #27ae60;
}

.country-code {
  position: absolute;
  left: 50px;
  bottom: 8px;
  color: #fffcfc;
  font-family: Roboto;
  font-size: 16px;
}

.LandingPage {
  text-align: center;
}

.LandingPage-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .LandingPage-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.LandingPage-header {

}

.img {
  /* height: 370;
  width: 370; */
  width: 91px;
  margin-bottom: calc(30vh - 100px);
}

.LandingPage-link {
  color: #61dafb;
}

.mini-sect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.TwoFactorAuth-header {

}

.validation-code-form {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-code-input {
  display: block;
  width: 80%;
  background-color: transparent;
  color: #fffcfc;
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.25em;
  text-align: center;
  border: 0;
  border-bottom: #bdbdbd 1px solid;
  border-radius: 0px;
  padding-bottom: 7px;
  margin-top: 20px;
}

.validation-code-submit-button {
  display: block;
  width: 50%;
  margin-top: 20px;
  /* background: #6fcf97; */
  background: #0066cc;
  border-radius: 27px;
  border: 0;
  font-family: Proxima-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #f2f2f2;
  height: 55px;
  cursor: pointer;
}

.validation-code-error {
  /* color: #c4352b; */
  color: #fff;
}

.bottom-space {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-desc {
  width: 90%;
}

/* .bottom {
  margin-bottom: 40vh;
} */


.Complete-header {
  justify-content: space-between;
  background: #fec100;
  background-image: url(/static/media/complete-page-bg-img-min.d600dade.png);
  background-image: url(/static/media/complete-page-bg-img-min.d600dade.png), linear-gradient(180deg, #fdbc00 0%, #ffc500 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.easy-text {
  font-family: Proxima-Bold;
  font-style: normal;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 5%;
  color: #333333;
}

