@font-face {
  font-family: "Proxima";
  src: local("Proxima"),
    url(./assets/fonts/ProximaNova-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Proxima-Bold";
  src: local("Proxima-Bold"),
    url(./assets/fonts/Proxima\ Nova\ Alt\ Bold.otf) format("opentype");
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
