.PhoneInput {
  flex: 1;
}
.PhoneInputCountryIcon {
  border-radius: 360px !important;
  overflow: hidden !important;
  border-width: 0px !important;
  width: 100%;
  height: 100%;
}
.PhoneInputCountrySelectArrow {
  display: none !important;
}
.PhoneInputInput {
  border: 0px !important;
  background-color: transparent;
  color: #fffcfc;
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.25em;
  text-align: left;

  border-radius: 0px;
  border-bottom: #bdbdbd 1px solid !important;
  padding-bottom: 7px;
  padding-left: 50px;
  box-sizing: border-box;
  width: calc(100% - 50px);
}
.wrapp {
  position: relative;
  display: flex;
  flex-direction: row;
  /* border-bottom: #bdbdbd 1px solid; */
  /* justify-content: space-between; */
  /* padding-bottom: 7px; */
}
.verify-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  position: absolute;
  right: 0;
}
.verify-badge img {
  width: 15px;
  height: 15px;
}
.verify-badge p {
  margin: 0px;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #27ae60;
}

.country-code {
  position: absolute;
  left: 50px;
  bottom: 8px;
  color: #fffcfc;
  font-family: Roboto;
  font-size: 16px;
}
